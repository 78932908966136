import store from '@/store'

let webSocketsService = {}

webSocketsService.install = function(Vue) {
  //A changer avant la mise en prod, test : 'wss://fg0test.radioshop.net:8088'
  const webSocketUrl = 'wss://fg0.radioshop.net'
  let ws
  let reconnectInterval = 5000 // Intervalle de reconnexion en ms

  Vue.prototype.$webSocketsConnect = () => {
    if (!ws) connectWebSocket()
  }

  Vue.prototype.$endWebsocket = () => {
    // eslint-disable-next-line no-console
    const idTransaction = store.state.message.newMessage.idTransaction
    if (ws.readyState !== WebSocket.CLOSED) {
      const msgEnd = JSON.stringify({
        Header: {
          Type: 'ProviderTransaction',
          idbrand: store.state.user.user.visibleBrands[0].id,
        },
        Content: {
          IdTransaction: idTransaction,
        },
      })

      ws.send(msgEnd)
      ws.close()
      ws = null
    }
  }

  Vue.prototype.$webSocketsSend = (data) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(data)
    } else {
      // eslint-disable-next-line no-console
      console.error('WebSocket is not connected.')
    }
  }

  Vue.prototype.$sendCompositing = (fileuuid) => {
    store.dispatch('message/_setNewMessageProp', {
      key: 'messageVoiceState',
      value: 'compositing',
      index: 0,
    })
    const compositingAudio = JSON.stringify({
      Content: {
        IdTransaction: store.state.message.newMessage.idTransaction,
        Parameters: {
          FileUuid: fileuuid,
          IdCompositeMedia:
            store.state.message.newMessage.selectedCompositing[0].id,
          Nudge0: store.state.message.newMessage.Nudge0[0],
          Nudge1: store.state.message.newMessage.Nudge1[0],
          Nudge2: store.state.message.newMessage.Nudge2[0],
          Nudge3: store.state.message.newMessage.Nudge3[0],
          Volume0: store.state.message.newMessage.Volume0[0],
          Volume1: store.state.message.newMessage.Volume1[0],
          Volume2: store.state.message.newMessage.Volume2[0],
          Volume3: store.state.message.newMessage.Volume3[0],
        },
        Provider: 'Composer',
      },
      Header: {
        Type: 'ProviderRequest',
        idbrand: store.state.user.user.visibleBrands[0].id,
        Tag: 'Composer',
      },
    })
    ws.send(compositingAudio)
  }

  Vue.prototype.$removeCompositing = (index) => {
    store.dispatch('message/_setNewMessageProp', {
      key: 'messageVoiceState',
      value: 'done',
      index: index,
    })

    store.dispatch('message/_setNewMessageProp', {
      key: 'fileuuidComposit',
      value: null,
      index: index,
    })
  }

  const connectWebSocket = () => {
    ws = new WebSocket(webSocketUrl)

    ws.onopen = () => {
      authSocket()
    }

    ws.onmessage = (event) => {
      try {
        const msg = JSON.parse(event.data)
        handleWebSocketMessage(msg)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    }

    ws.onerror = (error) => {
      // eslint-disable-next-line no-console
      console.error('WebSocket error:', error)
      ws.close()
      setTimeout(() => {
        connectWebSocket()
      }, reconnectInterval)
    }
  }

  const authSocket = () => {
    const msgAuth = JSON.stringify({
      Header: {
        Type: 'Auth',
        Token: store.state.user.user.token,
      },
    })
    ws.send(msgAuth)
  }

  const beginTransaction = () => {
    const msgTransaction = JSON.stringify({
      Header: {
        Type: 'ProviderTransaction',
        idbrand: store.state.user.user.visibleBrands[0].id,
      },
    })
    ws.send(msgTransaction)
  }

  const sendDiscovered = () => {
    const discovered = JSON.stringify({
      Header: {
        Type: 'Discovered',
      },
    })
    ws.send(discovered)
  }
  const handleWebSocketMessage = (msg) => {
    if (msg.Result.Code === '103') {
      Vue.$toast.error(
        'Votre compte ne dispose pas des droits necessaires pour accéder à cette fonctionnalité'
      )
      store.dispatch('message/_setWebsocketError', true)
      return
    }
    switch (msg.Header.Type) {
      case 'AuthResult':
        beginTransaction()
        break
      case 'ProviderTransactionResult':
        store.dispatch('message/_setNewMessageProp', {
          key: 'idTransaction',
          value: msg.Result.IdTransaction,
        })
        break
      case 'ProviderRequestResult':
        handleProviderRequestResult(msg)
        break
      case 'discover':
        sendDiscovered()
        break
      default:
        // eslint-disable-next-line no-console
        console.warn('Unhandled message type:', msg.Header.Type)
    }
  }

  const handleProviderRequestResult = (msg) => {
    if (msg.Result.FileUuid) {
      //FileAudio generated case
      if (msg.Header.Tag.includes('ElevenLabs')) {
        const index = msg.Header.Tag.split('-')[1]
        store.dispatch('message/_setNewMessageProp', {
          key: 'fileuuid',
          value: msg.Result.FileUuid,
          index: index,
        })
        //no compositing
        if (
          !store.state.message.newMessage.selectedCompositing[0] ||
          store.state.message.newMessage.selectedCompositing[0].id < 0
        ) {
          Vue.prototype.$removeCompositing(index)
          return
        }
        Vue.prototype.$sendCompositing(msg.Result.FileUuid)
      } else if (msg.Header.Tag.includes('Composer')) {
        const index = msg.Header.Tag.split('-')[1]
        store.dispatch('message/_setNewMessageProp', {
          key: 'messageVoiceState',
          value: 'done',
          index: index,
        })
        store.dispatch('message/_setNewMessageProp', {
          key: 'fileuuidComposit',
          value: msg.Result.FileUuid,
          index: index,
        })
      }
    } else {
      //chatGPT provider
      const messagesText = msg.Result?.Messages

      // Ajout de la propriété 'selected' à chaque objet
      messagesText.forEach((e) => {
        e.selected = true
      })

      store.dispatch('message/_setNewMessageProp', {
        key: 'messagesContent',
        value: messagesText,
      })
      store.dispatch('message/_setNewMessageProp', {
        key: 'messageGenerationState',
        value: 'done',
      })
    }
  }
}

export default webSocketsService
