import client from './axios.js'

export default {
  getSkeletonGroups(params) {
    return client.get('/skeleton/tags/list', { params })
  },
  getSkeletons(params) {
    return client.get('/skeleton/list', { params })
  },
  getSkeletonCover(idImage) {
    return client.get(`/skeleton/${idImage}/cover`)
  },
  getScheduleList(params) {
    return client.post('/schedule/list', params)
  },
  getSchedule(id) {
    return client.get(`/schedule/${id}`)
  },
  postSchedule(params) {
    return client.post('/schedule', params)
  },
  deleteSchedule(id, idSite) {
    return client.delete(`/schedule/${id}?idSite=${idSite}`)
  },
  generatePlaylist(idSite) {
    return client.post(`/playlist/generate/${idSite}`)
  },
  getSpotifyTracklist(idPlaylist) {
    return client.get(`/media/spotifytracklist/${idPlaylist}`)
  },
  getSpotifyPlaylistInfo(idPlaylist) {
    return client.get(`/media/spotifyplaylistinfos/${idPlaylist}`)
  },
  getByIsrcPool(params) {
    return client.post(`/media/isrcpool/`, params)
  },
  getByNamePool(params) {
    return client.post('/media/namepool/', params)
  },
  searchTrackCumulative(params) {
    return client.post('/media/fullSearch', params)
  },
  createEditSkeleton(params) {
    return client.post('/skeleton', params)
  },
  getStreams(id, params) {
    if (!id) return
    return client.get(`/brand/${id}/stream/`, { params })
  },
  getSkeleton(id) {
    return client.get(`/skeleton/${id}`)
  },
  getSkeletonLineEditMode(trackIds) {
    return client.post('/media/all/', trackIds)
  },
  deleteSkeletonCheck(idSkeleton) {
    return client.delete(`/skeleton/${idSkeleton}/check`)
  },
  deleteSkeleton(idSkeleton) {
    return client.delete(`/skeleton/${idSkeleton}`)
  },
  getSkeletonPermalinks(idSkeleton) {
    return client.get(`/skeleton/${idSkeleton}/permalinks`)
  },
  createSkeletonPermalink(idSkeleton, limit) {
    return client.post(`/permalink/${idSkeleton}`, { limit })
  },
  removeSkeletonPermalink(idPermalink) {
    return client.delete(`/permalink/${idPermalink}`)
  },
}
